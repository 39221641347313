<template>
  <!-- 订单出库明细管理 -->
  <div class="purchase-plan-list">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { impOrderInvoice } from '@/api/transactionData'
export default {
  components: {
    FormSearch,
    Table,
    Pagination
  },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      formItemArr: [
        { type: 'input', label: '采购方企业', value: 'buyerCmpName', width: '92px' },
        { type: 'input', label: '销售方企业', value: 'sellerCmpName', width: '92px' },
        { type: 'input', label: '合同编号', value: 'contractNo', width: '92px' },
        { type: 'input', label: '发票代码', value: 'invoiceCode', width: '92px' },
        { type: 'input', label: '发票号码', value: 'invoiceNo', width: '92px' }
        // { type: "input", label: "发票校验码", value: "checkCode",width:'100px' },
      ],
      itemData: [
        { label: '采购方企业', prop: 'buyerCmpName', width: 160 },
        { label: '销售方企业', prop: 'sellerCmpName', width: 120 },
        {
          label: '发票代码',
          prop: 'invoiceCode',
          width: 120
        },
        { label: '发票号码', prop: 'invoiceNo', width: 120 },
        { label: '开票日期', prop: 'openDate', width: 120 },
        { label: '含税金额(元)', prop: 'includeTaxAmt', width: 120 },
        { label: '税额(元)', prop: 'taxAmt', width: 120 },
        { label: '税率(%)', prop: 'taxRate', width: 120 }
        // { label: "创建时间", prop: "createTime", width: 140 }
      ],
      listData: [],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable }
      ],
      loading: false,
      total: 0,
      paymentObj: {}
    }
  },
  methods: {
    // 获取数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      impOrderInvoice(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 详情
    viewInfoTable({ id }) {
      this.$router.push({
        path: '/transactionData/transactionDataInvoiceDetails',
        query: { id }
      })
    }
  }
}
</script>
